import React from 'react'

export default function Loader() {
  return (
    <div className="preloader">
        <div className="preloader-circle"></div>
        <div className="preloader-circle-2"></div>
    </div>
  )
}
