/* eslint-disable @typescript-eslint/no-unused-vars */


export default function ChannelBrands() {
    return (
        <section className="brand-five">
            <div className="container">
                {/* brand-five-inner */}
                {/* <div className="brand-five-inner text-center">
                    <div className="section-title">
                        <h6>Watch <span>300+</span> Channel </h6>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 col-lg-2 col-md-4 col-sm-4 col-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="600ms">
                            <div className="brand-five-inner-img">
                                <Link to="">
                                    <img src="/assets/images/internet-providers/at&t.svg" alt="at&t" width={160} />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-2 col-lg-2 col-md-4 col-sm-4 col-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="500ms">
                            <div className="brand-five-inner-img">
                                <Link to="">
                                    <img src="/assets/images/internet-providers/optimum.svg" alt="optimum" width={160} />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-2 col-lg-2 col-md-4 col-sm-4 col-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="brand-five-inner-img">
                                <Link to="">
                                    <img src="/assets/images/internet-providers/spectrum.svg" alt="spectrum" width={160} />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-2 col-lg-2 col-md-4 col-sm-4 col-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="300ms">
                            <div className="brand-five-inner-img">
                                <Link to="">
                                    <img src="/assets/images/internet-providers/verizon.svg" alt="verizon" width={160} />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-2 col-lg-2 col-md-4 col-sm-4 col-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="200ms">
                            <div className="brand-five-inner-img">
                                <Link to="">
                                    <img src="/assets/images/internet-providers/T-Mobile.png" alt="T-Mobile" width={160} />
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-2 col-lg-2 col-md-4 col-sm-4 col-6 wow fadeInLeft animated" data-wow-duration="1500ms" data-wow-delay="100ms">
                            <div className="brand-five-inner-img">
                                <Link to="">
                                    <img src="/assets/images/internet-providers/xfinity.png" alt="xfinity" width={160} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    )
}
