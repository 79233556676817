import React from 'react';
// import logo from './logo.svg';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from './Router/routes';
import './App.css';

function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
